import React, { useRef, useEffect, useContext } from 'react';
import { gsap, Power1 } from 'gsap';
import ThemeLink from 'components/UI/ThemeLink';
import {
  StyledMenu,
  MenuContainer,
  MenuContent,
  Exerpt,
  Copyright,
  Nav,
  NavList,
  NavItem,
  NavLink,
  NavLinkExternal,
} from './styles';

import AnimationContext from 'context/AnimationProvider';

import linksAnimation from 'utils/animations/linksAnimation';

const Menu = ({ data, closeAnim, handleOnClick }) => {
  const { menuItems, secondaryMenuItems, excerpt } = data;

  const { setAnimationExit } = useContext(AnimationContext);

  const ref = useRef();
  const linksRef = useRef();
  const tl = useRef();

  useEffect(() => {
    document?.addEventListener('keydown', escFunction, false);
    document?.addEventListener('mousedown', handleClickOutside);

    const linksTl = linksAnimation(linksRef).timeScale(1.5);

    tl.current = gsap
      .timeline()
      .fromTo(
        ref.current,
        { x: '100%' },
        { x: 0, duration: 0.8, ease: Power1.easeOut },
        '0'
      )
      .add(linksTl, '-=0.7');

    return () => {
      setAnimationExit(false);
      document.removeEventListener('keydown', escFunction, false);
      document.removeEventListener('mousedown', handleClickOutside);
      tl.current.kill();
    };
  }, []);

  useEffect(() => {
    if (closeAnim) {
      exitMenu();
    }
  }, [closeAnim]);

  const escFunction = (e) => {
    if (e.keyCode === 27) {
      handleOnClick();
    }
  };

  const handleClickOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      handleOnClick();
    }
  };

  const exitMenu = () => {
    tl.current.timeScale(2).reverse(0);
  };

  return (
    <>
      <StyledMenu ref={ref}>
        <MenuContainer>
          <MenuContent>
            <Nav>
              <NavList ref={linksRef}>
                {menuItems &&
                  menuItems.map((item) => {
                    const route = item?.externalLinkOrAnchor
                      ? item?.externalLinkOrAnchor
                      : item?.internalLink?.handle
                      ? `/${item?.internalLink?.handle}`
                      : `/${item?.internalLink?.handle}`;

                    return (
                      <NavItem
                        key="item.id"
                        onClick={() => handleOnClick(true)}
                      >
                        <ThemeLink
                          to={route}
                          key={item.id}
                          theme="secondary"
                          className="isNavigation"
                          exitDelay={1.5}
                        >
                          {item.title}
                        </ThemeLink>
                      </NavItem>
                    );
                  })}
              </NavList>
            </Nav>
            <Exerpt>{/* {excerpt} */}</Exerpt>

            <Nav>
              <NavList>
                {secondaryMenuItems &&
                  secondaryMenuItems.map((item) => {
                    const route = item?.externalLinkOrAnchor
                      ? item?.externalLinkOrAnchor
                      : item?.internalLink?.handle
                      ? `/${item?.internalLink?.handle}`
                      : `/${item?.internalLink?.handle}`;


                    return !item?.externalLinkOrAnchor ? (
                      <NavItem key="item.id">
                        <NavLink title={item.title} to={route} key={item.id}>
                          {item.title}
                        </NavLink>
                      </NavItem>
                    ) : (
                      <NavItem key="item.id">
                        <NavLinkExternal
                          target={item.openInNewTab ? '_blank' : '_self'}
                          title={item.title}
                          href={item?.externalLinkOrAnchor}
                        >
                          {item.title}
                        </NavLinkExternal>
                      </NavItem>
                    );
                  })}
              </NavList>
            </Nav>

            <Copyright>
              © Université de Montréal, 2021. Tous droits réservés. Site web par
              <a target="_blank" href="https://republik.ca/en/">
                {' '}
                Republik
              </a>{' '}
              x
              <a target="_blank" href="https://field-office.ca/">
                {' '}
                Field Office
              </a>
            </Copyright>
          </MenuContent>
        </MenuContainer>
      </StyledMenu>
    </>
  );
};

export default Menu;
