import styled, { css } from 'styled-components';

export const h1 = css`
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: ${(props) => props.theme.fonts.h1_mobile};
  letter-spacing: ${(props) => props.theme.letterSpacing.h1_mobile};
  line-height: ${(props) => props.theme.lineHeight.h1_mobile};
  font-weight: ${(props) => props.theme.fontWeight.h1_mobile};

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    font-size: ${(props) => props.theme.fonts.h1_tablet};
    letter-spacing: ${(props) => props.theme.letterSpacing.h1_tablet};
    line-height: ${(props) => props.theme.lineHeight.h1_tablet};
    font-weight: ${(props) => props.theme.fontWeight.h1_tablet};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    font-size: ${(props) => props.theme.fonts.h1_desktop};
    letter-spacing: ${(props) => props.theme.letterSpacing.h1_desktop};
    line-height: ${(props) => props.theme.lineHeight.h1_desktop};
    font-weight: ${(props) => props.theme.fontWeight.h1_desktop};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    font-size: ${(props) => props.theme.fonts.h1_large_desktop};
    letter-spacing: ${(props) => props.theme.letterSpacing.h1_large_desktop};
    line-height: ${(props) => props.theme.lineHeight.h1_large_desktop};
    font-weight: ${(props) => props.theme.fontWeight.h1_large_desktop};
  }

  * {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
`;
export const h2 = css`
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: ${(props) => props.theme.fonts.h2_mobile};
  letter-spacing: ${(props) => props.theme.letterSpacing.h2_mobile};
  line-height: ${(props) => props.theme.lineHeight.h2_mobile};
  font-weight: ${(props) => props.theme.fontWeight.h2_mobile};

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    font-size: ${(props) => props.theme.fonts.h2_tablet};
    letter-spacing: ${(props) => props.theme.letterSpacing.h2_tablet};
    line-height: ${(props) => props.theme.lineHeight.h2_tablet};
    font-weight: ${(props) => props.theme.fontWeight.h2_tablet};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    font-size: ${(props) => props.theme.fonts.h2_desktop};
    letter-spacing: ${(props) => props.theme.letterSpacing.h2_desktop};
    line-height: ${(props) => props.theme.lineHeight.h2_desktop};
    font-weight: ${(props) => props.theme.fontWeight.h2_desktop};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    font-size: ${(props) => props.theme.fonts.h2_large_desktop};
    letter-spacing: ${(props) => props.theme.letterSpacing.h2_large_desktop};
    line-height: ${(props) => props.theme.lineHeight.h2_large_desktop};
    font-weight: ${(props) => props.theme.fontWeight.h2_large_desktop};
  }

  * {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
`;
export const h3 = css`
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: ${(props) => props.theme.fonts.h3_mobile};
  letter-spacing: ${(props) => props.theme.letterSpacing.h3_mobile};
  line-height: ${(props) => props.theme.lineHeight.h3_mobile};
  font-weight: ${(props) => props.theme.fontWeight.h3_mobile};

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    font-size: ${(props) => props.theme.fonts.h3_tablet};
    letter-spacing: ${(props) => props.theme.letterSpacing.h3_tablet};
    line-height: ${(props) => props.theme.lineHeight.h3_tablet};
    font-weight: ${(props) => props.theme.fontWeight.h3_tablet};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    font-size: ${(props) => props.theme.fonts.h3_desktop};
    letter-spacing: ${(props) => props.theme.letterSpacing.h3_desktop};
    line-height: ${(props) => props.theme.lineHeight.h3_desktop};
    font-weight: ${(props) => props.theme.fontWeight.h3_desktop};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    font-size: ${(props) => props.theme.fonts.h3_large_desktop};
    letter-spacing: ${(props) => props.theme.letterSpacing.h3_large_desktop};
    line-height: ${(props) => props.theme.lineHeight.h3_large_desktop};
    font-weight: ${(props) => props.theme.fontWeight.h3_large_desktop};
  }

  * {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
`;
export const h4 = css`
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: ${(props) => props.theme.fonts.h4_mobile};
  letter-spacing: ${(props) => props.theme.letterSpacing.h4_mobile};
  line-height: ${(props) => props.theme.lineHeight.h4_mobile};
  font-weight: ${(props) => props.theme.fontWeight.h4_mobile};

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    font-size: ${(props) => props.theme.fonts.h4_tablet};
    letter-spacing: ${(props) => props.theme.letterSpacing.h4_tablet};
    line-height: ${(props) => props.theme.lineHeight.h4_tablet};
    font-weight: ${(props) => props.theme.fontWeight.h4_tablet};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    font-size: ${(props) => props.theme.fonts.h4_desktop};
    letter-spacing: ${(props) => props.theme.letterSpacing.h4_desktop};
    line-height: ${(props) => props.theme.lineHeight.h4_desktop};
    font-weight: ${(props) => props.theme.fontWeight.h4_desktop};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    font-size: ${(props) => props.theme.fonts.h4_large_desktop};
    letter-spacing: ${(props) => props.theme.letterSpacing.h4_large_desktop};
    line-height: ${(props) => props.theme.lineHeight.h4_large_desktop};
    font-weight: ${(props) => props.theme.fontWeight.h4_large_desktop};
  }

  * {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
`;

export const h5 = css`
  ${h4};
`;

export const h6 = css`
  ${h4};
`;

export const p = css`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-size: ${(props) => props.theme.fonts.body_mobile};
  letter-spacing: ${(props) => props.theme.letterSpacing.body_mobile};
  line-height: ${(props) => props.theme.lineHeight.body_mobile};
  font-weight: ${(props) => props.theme.fontWeight.body_mobile};

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    font-size: ${(props) => props.theme.fonts.body_tablet};
    letter-spacing: ${(props) => props.theme.letterSpacing.body_tablet};
    line-height: ${(props) => props.theme.lineHeight.body_tablet};
    font-weight: ${(props) => props.theme.fontWeight.body_tablet};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    font-size: ${(props) => props.theme.fonts.body_desktop};
    letter-spacing: ${(props) => props.theme.letterSpacing.body_desktop};
    line-height: ${(props) => props.theme.lineHeight.body_desktop};
    font-weight: ${(props) => props.theme.fontWeight.body_desktop};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    font-size: ${(props) => props.theme.fonts.body_large_desktop};
    letter-spacing: ${(props) => props.theme.letterSpacing.body_large_desktop};
    line-height: ${(props) => props.theme.lineHeight.body_large_desktop};
    font-weight: ${(props) => props.theme.fontWeight.body_large_desktop};
  }

  &.body-xl {
    font-family: ${(props) => props.theme.fontFamily.primary};
    font-size: ${(props) => props.theme.fonts.body_xl_mobile};
    letter-spacing: ${(props) => props.theme.letterSpacing.body_xl_mobile};
    line-height: ${(props) => props.theme.lineHeight.body_xl_mobile};
    font-weight: ${(props) => props.theme.fontWeight.body_xl_mobile};

    @media (min-width: ${(props) => props.theme.breakpoints.s}) {
      font-size: ${(props) => props.theme.fonts.body_xl_tablet};
      letter-spacing: ${(props) => props.theme.letterSpacing.body_xl_tablet};
      line-height: ${(props) => props.theme.lineHeight.body_xl_tablet};
      font-weight: ${(props) => props.theme.fontWeight.body_xl_tablet};
    }

    @media (min-width: ${(props) => props.theme.breakpoints.l}) {
      font-size: ${(props) => props.theme.fonts.body_xl_desktop};
      letter-spacing: ${(props) => props.theme.letterSpacing.body_xl_desktop};
      line-height: ${(props) => props.theme.lineHeight.body_xl_desktop};
      font-weight: ${(props) => props.theme.fontWeight.body_xl_desktop};
    }

    @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
      font-size: ${(props) => props.theme.fonts.body_xl_large_desktop};
      letter-spacing: ${(props) =>
        props.theme.letterSpacing.body_xl_large_desktop};
      line-height: ${(props) => props.theme.lineHeight.body_xl_large_desktop};
      font-weight: ${(props) => props.theme.fontWeight.body_xl_large_desktop};
    }
  }

  &.body-l {
    font-family: ${(props) => props.theme.fontFamily.primary};
    font-size: ${(props) => props.theme.fonts.body_l_mobile};
    letter-spacing: ${(props) => props.theme.letterSpacing.body_l_mobile};
    line-height: ${(props) => props.theme.lineHeight.body_l_mobile};
    font-weight: ${(props) => props.theme.fontWeight.body_l_mobile};

    @media (min-width: ${(props) => props.theme.breakpoints.s}) {
      font-size: ${(props) => props.theme.fonts.body_l_tablet};
      letter-spacing: ${(props) => props.theme.letterSpacing.body_l_tablet};
      line-height: ${(props) => props.theme.lineHeight.body_l_tablet};
      font-weight: ${(props) => props.theme.fontWeight.body_l_tablet};
    }

    @media (min-width: ${(props) => props.theme.breakpoints.l}) {
      font-size: ${(props) => props.theme.fonts.body_l_desktop};
      letter-spacing: ${(props) => props.theme.letterSpacing.body_l_desktop};
      line-height: ${(props) => props.theme.lineHeight.body_l_desktop};
      font-weight: ${(props) => props.theme.fontWeight.body_l_desktop};
    }

    @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
      font-size: ${(props) => props.theme.fonts.body_l_large_desktop};
      letter-spacing: ${(props) =>
        props.theme.letterSpacing.body_l_large_desktop};
      line-height: ${(props) => props.theme.lineHeight.body_l_large_desktop};
      font-weight: ${(props) => props.theme.fontWeight.body_l_large_desktop};
    }
  }

  &.body-s {
    font-family: ${(props) => props.theme.fontFamily.primary};
    font-size: ${(props) => props.theme.fonts.body_s_mobile};
    letter-spacing: ${(props) => props.theme.letterSpacing.body_s_mobile};
    line-height: ${(props) => props.theme.lineHeight.body_s_mobile};
    font-weight: ${(props) => props.theme.fontWeight.body_s_mobile};

    @media (min-width: ${(props) => props.theme.breakpoints.s}) {
      font-size: ${(props) => props.theme.fonts.body_s_tablet};
      letter-spacing: ${(props) => props.theme.letterSpacing.body_s_tablet};
      line-height: ${(props) => props.theme.lineHeight.body_s_tablet};
      font-weight: ${(props) => props.theme.fontWeight.body_s_tablet};
    }

    @media (min-width: ${(props) => props.theme.breakpoints.l}) {
      font-size: ${(props) => props.theme.fonts.body_s_desktop};
      letter-spacing: ${(props) => props.theme.letterSpacing.body_s_desktop};
      line-height: ${(props) => props.theme.lineHeight.body_s_desktop};
      font-weight: ${(props) => props.theme.fontWeight.body_s_desktop};
    }

    @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
      font-size: ${(props) => props.theme.fonts.body_s_large_desktop};
      letter-spacing: ${(props) =>
        props.theme.letterSpacing.body_s_large_desktop};
      line-height: ${(props) => props.theme.lineHeight.body_s_large_desktop};
      font-weight: ${(props) => props.theme.fontWeight.body_s_large_desktop};
    }
  }

  &.article-body {
    font-family: ${(props) => props.theme.fontFamily.primary};
    font-size: ${(props) => props.theme.fonts.article_body_mobile};
    letter-spacing: ${(props) => props.theme.letterSpacing.article_body_mobile};
    line-height: ${(props) => props.theme.lineHeight.article_body_mobile};
    font-weight: ${(props) => props.theme.fontWeight.article_body_mobile};

    @media (min-width: ${(props) => props.theme.breakpoints.s}) {
      font-size: ${(props) => props.theme.fonts.article_body_tablet};
      letter-spacing: ${(props) =>
        props.theme.letterSpacing.article_body_tablet};
      line-height: ${(props) => props.theme.lineHeight.article_body_tablet};
      font-weight: ${(props) => props.theme.fontWeight.article_body_tablet};
    }

    @media (min-width: ${(props) => props.theme.breakpoints.l}) {
      font-size: ${(props) => props.theme.fonts.article_body_desktop};
      letter-spacing: ${(props) =>
        props.theme.letterSpacing.article_body_desktop};
      line-height: ${(props) => props.theme.lineHeight.article_body_desktop};
      font-weight: ${(props) => props.theme.fontWeight.article_body_desktop};
    }

    @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
      font-size: ${(props) => props.theme.fonts.article_body_large_desktop};
      letter-spacing: ${(props) =>
        props.theme.letterSpacing.article_body_large_desktop};
      line-height: ${(props) =>
        props.theme.lineHeight.article_body_large_desktop};
      font-weight: ${(props) =>
        props.theme.fontWeight.article_body_large_desktop};
    }

    &.eyebrow-text {
      font-family: ${(props) => props.theme.fontFamily.primary};
      font-size: ${(props) => props.theme.fonts.eyebrow_text_mobile};
      letter-spacing: ${(props) =>
        props.theme.letterSpacing.eyebrow_text_mobile};
      line-height: ${(props) => props.theme.lineHeight.eyebrow_text_mobile};
      font-weight: ${(props) => props.theme.fontWeight.eyebrow_text_mobile};

      @media (min-width: ${(props) => props.theme.breakpoints.s}) {
        font-size: ${(props) => props.theme.fonts.eyebrow_text_tablet};
        letter-spacing: ${(props) =>
          props.theme.letterSpacing.eyebrow_text_tablet};
        line-height: ${(props) => props.theme.lineHeight.eyebrow_text_tablet};
        font-weight: ${(props) => props.theme.fontWeight.eyebrow_text_tablet};
      }

      @media (min-width: ${(props) => props.theme.breakpoints.l}) {
        font-size: ${(props) => props.theme.fonts.eyebrow_text_desktop};
        letter-spacing: ${(props) =>
          props.theme.letterSpacing.eyebrow_text_desktop};
        line-height: ${(props) => props.theme.lineHeight.eyebrow_text_desktop};
        font-weight: ${(props) => props.theme.fontWeight.eyebrow_text_desktop};
      }

      @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
        font-size: ${(props) => props.theme.fonts.eyebrow_text_large_desktop};
        letter-spacing: ${(props) =>
          props.theme.letterSpacing.eyebrow_text_large_desktop};
        line-height: ${(props) =>
          props.theme.lineHeight.eyebrow_text_large_desktop};
        font-weight: ${(props) =>
          props.theme.fontWeight.eyebrow_text_large_desktop};
      }
    }
  }
`;

export const small = css`
  ${p};
`;

export const blockquote = css`
  ${p};
`;

export const li = css`
  ${p};
`;

export const TypographyStyled = styled.p``;
