import styled from 'styled-components';

export const IntViewportHeightWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr auto 1fr;
  min-height: 100vh;
  width: 100%;
  position: relative;
  grid-template-columns: 100%;

  main {
    grid-row: 2;
  }

  footer {
    grid-row: 3;
    align-self: end;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    grid-template-rows: 1fr auto auto;

    main {
      grid-row: 2;
    }

    footer {
      grid-row: 3;
    }
  }

  @media (min-width: 1680px) {
    grid-template-rows: 1fr auto 1fr;
  }
`;
