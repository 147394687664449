import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-use';
import { useStaticQuery, graphql } from 'gatsby';
import { useDisableBodyScroll } from 'utils/hooks/useDisableBodyScroll';
import useWindowSizes from 'utils/hooks/useWindowSizes';
import Icon from 'components/Icon/';
import Menu from 'components/Menu';
import {
  HeaderStyled,
  HeaderContainer,
  LogoContainer,
  BurgerMenu,
  Text,
  BurgerContainer,
  BurgerContent,
} from './styles';

import AnimationContext from 'context/AnimationProvider';

const Header = ({ headerColor, isArticle }) => {
  const mobile = useWindowSizes().windowWidth < 768;

  const { setAnimationExit } = useContext(AnimationContext);

  const [isOpen, setIsOpen] = useState(false);
  const [closeAnim, setCloseAnim] = useState(false);
  const isBlueModifier = headerColor === 'blue';

  useDisableBodyScroll(isOpen);

  const { contentfulNavigation } = useStaticQuery(graphql`
    {
      contentfulNavigation {
        excerpt
        contentful_id
        id
        menuItems {
          externalLinkOrAnchor
          openInNewTab
          title
          id
          internalLink {
            __typename
            ... on ContentfulBlogPage {
              id
              handle
            }
            ... on ContentfulGenericPage {
              id
              handle
            }
            ... on ContentfulParentResourcesPage {
              id
              handle
            }
          }
        }
        secondaryMenuItems {
          externalLinkOrAnchor
          openInNewTab
          title
          id
          internalLink {
            __typename
            ... on ContentfulBlogPage {
              id
              handle
            }
            ... on ContentfulGenericPage {
              id
              handle
            }
            ... on ContentfulParentResourcesPage {
              id
              handle
            }
          }
        }
      }
    }
  `);

  const data = contentfulNavigation;

  const location = useLocation().pathname;

  const isAnimatedPage = /\/besoin-aide/g.test(location);

  const handleOnClick = (allPageExit) => {
    const opened = !isOpen;

    if (!opened) {
      if (allPageExit) {
        setAnimationExit(true);
      }
      setCloseAnim(true);
      setTimeout(() => {
        setIsOpen(opened);
        setCloseAnim(false);
      }, 1500);
    } else {
      setIsOpen(opened);
    }
  };

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  const logoClick = () => {
    setAnimationExit(true);
    setTimeout(() => {
      setAnimationExit(false);
    }, 1900);
  };

  return (
    <>
      <HeaderStyled
        className={
          (isBlueModifier ? 'blue' : '') +
          ' ' +
          (isArticle ? 'isArticle' : '') +
          ' ' +
          (isOpen && !isBlueModifier ? 'isOpen' : '')
        }
      >
        <HeaderContainer className="Header-Container">
          <LogoContainer
            className="Logo-Container"
            to="/"
            exit={{ delay: isAnimatedPage ? 2 : 0 }}
            onClick={isAnimatedPage ? logoClick : undefined}
          >
            <Icon type="header-logo" className="Header-Logo" />
          </LogoContainer>
          {!mobile && (
            <Text className="Header-Text">{isOpen ? 'Fermer' : 'Menu'}</Text>
          )}
        </HeaderContainer>
        <BurgerContainer className="BurgerContainer">
          <BurgerContent>
            <BurgerMenu
              onClick={() => handleOnClick()}
              className="Burger-Menu"
              type="button"
            >
              <Icon
                type={isOpen ? 'burger-close' : 'burger'}
                className="Icon-Burgermenu"
              />
            </BurgerMenu>
          </BurgerContent>
        </BurgerContainer>
      </HeaderStyled>

      {isOpen ? (
        <Menu data={data} closeAnim={closeAnim} handleOnClick={handleOnClick} />
      ) : (
        <></>
      )}
    </>
  );
};

export default Header;
