import React from 'react';
import PropTypes from 'prop-types';
import useWindowSizes from 'utils/hooks/useWindowSizes';
import { IntViewportHeightWrapper } from './styles';

const IntViewportHeight = ({ children = null }) => {
  const height = useWindowSizes().windowHeight;

  return (
    <IntViewportHeightWrapper height={height}>
      {children}
    </IntViewportHeightWrapper>
  );
};

IntViewportHeight.propTypes = {
  children: PropTypes.node,
};

export default IntViewportHeight;
