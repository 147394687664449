import React, { useEffect, useState } from 'react';
import VideoBg from 'reactjs-videobg';
import useWindowSizes from 'utils/hooks/useWindowSizes';
import mp4Mobile from 'static/videos/blue_sky_desk.mp4';
import mp4Tablet from 'static/videos/blue_sky_desk.mp4';
import mp4Desktop from 'static/videos/blue_sky_desk.mp4';
import webmMobile from 'static/videos/blue_sky_desk.webm';
import webmTablet from 'static/videos/blue_sky_desk.webm';
import webmDesktop from 'static/videos/blue_sky_desk.webm';
import { VideoBgWrapper } from './styles';

const VideoBackground = ({ inBlock }) => {
  const tablet = useWindowSizes().windowWidth >= 768;
  const desktop = useWindowSizes().windowWidth >= 1280;

  const [mp4, setMp4] = useState(mp4Mobile);
  const [webm, setWebm] = useState(webmMobile);

  useEffect(() => {
    if (!tablet && !desktop) {
      setMp4(mp4Mobile);
      setWebm(webmMobile);
    }
    if (tablet && !desktop) {
      setMp4(mp4Tablet);
      setWebm(webmTablet);
    }
    if (desktop) {
      setMp4(mp4Desktop);
      setWebm(webmDesktop);
    }
  });

  return (
    <VideoBgWrapper>
      <VideoBg loop={true}>
        <VideoBg.Source src={mp4} type="video/mp4" />
        <VideoBg.Source src={webm} type="video/mp4" />
      </VideoBg>
    </VideoBgWrapper>
  );
};

export default VideoBackground;
