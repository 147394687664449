import styled, { css } from 'styled-components';
import Link from 'gatsby-plugin-transition-link';

export const StyledMenu = styled.div`
  position: fixed;
  left: 0;
  width: 100%;
  min-height: 100vh;
  z-index: 2;
  background-color: ${(props) => props.theme.colors.white};
  background-image: url('/images/menu-background.svg');
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: bottom right -700px;
  box-shadow: 0px 8px 24px rgba(30, 56, 74, 0.15);
  transform: translateX(100%);

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    background-size: auto 100%;
    background-position: bottom right -500px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    width: 50%;
    margin-left: 50%;
    background-size: auto 100%;
    background-position: bottom right -600px;
  }
`;

export const MenuContainer = styled.div`
  margin: 0 20px;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    margin: 0 60px;
  }
`;

export const MenuContent = styled.div`
  padding: 220px 0 280px;
  position: relative;
  min-height: 100vh;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    padding: 270px 0 120px 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    padding: 170px 0 200px 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xxl}) {
    padding: 220px 0 200px 0;
  }
`;

export const Exerpt = styled.p`
  max-width: 280px;
  margin: 30px 0;
  color: ${(props) => props.theme.colors.blue};
  font-size: ${(props) => props.theme.fonts.body_mobile};

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    max-width: 368px;
    font-size: ${(props) => props.theme.fonts.body_tablet};
    font-weight: ${(props) => props.theme.fontWeight.body_tablet};
    line-height: ${(props) => props.theme.lineHeight.body_tablet};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    font-size: ${(props) => props.theme.fonts.body_desktop};
    font-weight: ${(props) => props.theme.fontWeight.body_desktop};
    line-height: ${(props) => props.theme.lineHeight.body_desktop};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xxl}) {
    font-size: ${(props) => props.theme.fonts.body_large_desktop};
    font-weight: ${(props) => props.theme.fontWeight.body_large_desktop};
    line-height: ${(props) => props.theme.lineHeight.body_large_desktop};
  }
`;

export const Copyright = styled.p`
  position: absolute;
  left: calc(50% - 126px);
  bottom: 20px;
  max-width: 252px;
  margin: 0 auto;
  text-align: center;
  font-size: ${(props) => props.theme.fonts.copyright_text_mobile};
  line-height: ${(props) => props.theme.lineHeight.copyright_text_mobile};
  color: ${(props) => props.theme.colors.blue};

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    max-width: 352px;
    left: 0;
    text-align: left;
    font-size: ${(props) => props.theme.fonts.copyright_text_tablet};
    line-height: ${(props) => props.theme.lineHeight.copyright_text_tablet};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    bottom: 30px;
    font-size: ${(props) => props.theme.fonts.copyright_text_desktop};
    line-height: ${(props) => props.theme.lineHeight.copyright_text_desktop};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xxl}) {
    font-size: ${(props) => props.theme.fonts.copyright_text_large_desktop};
    line-height: ${(props) =>
      props.theme.lineHeight.copyright_text_large_desktop};
  }

  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.blue};
    font-size: inherit;
    line-height: inherit;
  }
`;

export const Nav = styled.nav``;
export const NavList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;

  & li:first-of-type {
    margin-top: 0;
  }
`;
export const NavItem = styled.li`
  margin: 0;
  padding: 0;

  svg {
    transition: transform 0.4s;
  }

  &:hover {
    svg {
      transform: translate(-15px, 0px) !important;
    }
  }
`;

export const ThemeLinkStyle = css`
  color: ${(props) => props.theme.colors.blue};
`;

export const NavLink = styled(Link)`
  color: ${(props) => props.theme.colors.blue};
  text-decoration: underline;
  font-size: ${(props) => props.theme.fonts.small_link_mobile};
  line-height: ${(props) => props.theme.lineHeight.small_link_mobile};
  font-weight: ${(props) => props.theme.fontWeight.body_mobile};

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    max-width: 352px;
    text-align: right;
    font-size: ${(props) => props.theme.fonts.small_link_tablet};
    line-height: ${(props) => props.theme.lineHeight.small_link_tablet};
    font-weight: ${(props) => props.theme.fontWeight.body_tablet};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    bottom: 30px;
    font-size: ${(props) => props.theme.fonts.small_link_desktop};
    line-height: ${(props) => props.theme.lineHeight.small_link_desktop};
    font-weight: ${(props) => props.theme.fontWeight.body_desktop};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xxl}) {
    font-size: ${(props) => props.theme.fonts.small_link_large_desktop};
    line-height: ${(props) => props.theme.lineHeight.small_link_large_desktop};
    font-weight: ${(props) => props.theme.fontWeight.body_large_desktop};
  }
`;

export const NavLinkExternal = styled.a`
  color: ${(props) => props.theme.colors.blue};
  text-decoration: underline;
  font-size: ${(props) => props.theme.fonts.small_link_mobile};
  line-height: ${(props) => props.theme.lineHeight.small_link_mobile};
  font-weight: ${(props) => props.theme.fontWeight.body_mobile};

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    max-width: 352px;
    text-align: right;
    font-size: ${(props) => props.theme.fonts.small_link_tablet};
    line-height: ${(props) => props.theme.lineHeight.small_link_tablet};
    font-weight: ${(props) => props.theme.fontWeight.body_tablet};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    bottom: 30px;
    font-size: ${(props) => props.theme.fonts.small_link_desktop};
    line-height: ${(props) => props.theme.lineHeight.small_link_desktop};
    font-weight: ${(props) => props.theme.fontWeight.body_desktop};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xxl}) {
    font-size: ${(props) => props.theme.fonts.small_link_large_desktop};
    line-height: ${(props) => props.theme.lineHeight.small_link_large_desktop};
    font-weight: ${(props) => props.theme.fontWeight.body_large_desktop};
  }
`
