import React, {useRef, useEffect} from 'react';
import { gsap, Power1 } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ProgressBar } from './styles';

gsap.registerPlugin(ScrollTrigger);

const ArticleProgressBar = () => {

  const refProgressBar = useRef();

  useEffect(() => {
    if(!refProgressBar.current) return;

    const main = document.getElementById('main');

    if(!main) return;

    gsap.set(refProgressBar.current, {scaleX:0});

    const tl = gsap.timeline( { 
      scrollTrigger: {
        trigger: main,
        start: "top top",
        end: "bottom bottom",
        scrub: true,
        toggleActions: "play reverse play reverse",
      }
    });

    tl.to(refProgressBar.current, { scaleX:1, duration:1, ease: Power1.easeOut })


    return(() => {
      tl.kill();
    })


  }, [refProgressBar.current]);

  return (
    <ProgressBar>
      <div ref={refProgressBar} className="progress-done"></div>
    </ProgressBar>
  )
};

export default ArticleProgressBar;