import styled, { css } from 'styled-components';

export const StyledFooter = styled.footer`
  padding-bottom: 54px;
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    padding-bottom: 44.56px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    padding-bottom: 83.66px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    padding-bottom: 89.66px;
  }

  &.BackgroundBlue {
    background-color: ${(props) => props.theme.colors.blue};
  }

  &.blue {
    //remove background after merge
    background-color: white;
    .Footer-Text {
      color: ${(props) => props.theme.colors.blue};
    }

    .Footer-Content {
      border-color: ${(props) => props.theme.colors.blue};
    }
  }
`;

export const FooterContainer = styled.div`
  width: 100%;
  opacity: 0.5;
`;

export const Text = styled.p`
  font-size: ${(props) => props.theme.fonts.small_tag_mobile};
  line-height: ${(props) => props.theme.lineHeight.article_body_mobile};
  color: ${(props) => props.theme.colors.white};
`;

export const FooterContent = styled.div`
  width: 100%;
  display: flex;
  padding-top: 11px;
  justify-content: space-between;
  /* border-top: 1px solid ${(props) => props.theme.colors.white}; */

  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
`;

export const FooterItems = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    margin-bottom: 20px;
  }
`;

export const ItemLink = styled.a`
  margin-right: 30px;

  &:last-child {
    margin-right: 0;
  }
`;

export const Item = styled.img`
  width: 86px;
  height: auto;

  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    width: 56px;
  }
`;
