import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = (props) => {
  const {
    seoTitle,
    seoDescription,
    seoSchema: schema,
    openGraphTitle,
    openGraphDescription,
    openGraphImage,
    location,
  } = props;

  const { site } = useStaticQuery(query);

  useEffect(() => {
    (function () {
      var s = document.createElement('script'),
        e = !document.body ? document.querySelector('head') : document.body;
      s.src = 'https://acsbapp.com/apps/app/dist/js/app.js';
      s.async = true;
      s.onload = function () {
        window.acsbJS.init({
          statementLink: '',
          footerHtml: '',
          hideMobile: false,
          hideTrigger: false,
          language: 'fr',
          position: 'right',
          leadColor: '#006BB6',
          triggerColor: '#006BB6',
          triggerRadius: '50%',
          triggerPositionX: 'right',
          triggerPositionY: 'bottom',
          triggerIcon: 'people',
          triggerSize: 'medium',
          triggerOffsetX: 20,
          triggerOffsetY: 20,
          mobile: {
            triggerSize: 'small',
            triggerPositionX: 'right',
            triggerPositionY: 'bottom',
            triggerOffsetX: 10,
            triggerOffsetY: 10,
            triggerRadius: '50%',
          },
        });
      };
      e.appendChild(s);
    })();
  }, []);

  const {
    siteMetadata: {
      ogLanguage,
      twitter,
      facebook,
    },
  } = site;
  let ogTitle = openGraphTitle || seoTitle;
  let ogImage = openGraphImage?.file?.url;

  return (
    <Helmet title={seoTitle}>
      <html lang={'fr'} />
      <meta
        content="width=device-width,initial-scale=1.0,user-scalable=yes"
        name="viewport"
      />
      <meta content="yes" name="apple-mobile-web-app-capable" />
      <meta
        content="black-translucent"
        name="apple-mobile-web-app-status-bar-style"
      />
      <meta content={seoTitle} name="apple-mobile-web-app-title" />
      <meta name="description" content={seoDescription} />
      <meta name="image" content={ogImage} />
      <meta name={seoTitle} content={seoDescription} />
      {/* Facebook */}
      {facebook && <meta property="og:site_name" content={facebook} />}
      <meta property="og:locale" content={ogLanguage} />
      <meta property="og:url" content={schema?._url || location.href} />
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={openGraphDescription} />
      <meta property="og:image" content={ogImage} />
      <meta content="1024" property="og:image:width" />
      <meta content="512" property="og:image:height" />
      <meta property="og:image:alt" content={openGraphDescription} />
      {/* Twitter */}
      {twitter && <meta name="twitter:creator" content={twitter} />}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={ogTitle} />
      <meta name="twitter:description" content={openGraphDescription} />
      <meta name="twitter:image" content={ogImage} />
      <meta content="1024" name="twitter:image:width" />
      <meta content="512" name="twitter:image:height" />
      <meta name="twitter:image:alt" content={openGraphDescription} />
      {/* {schemaOrg && (
        <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
      )} */}
    </Helmet>
  );
};

export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  banner: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  node: PropTypes.object,
  locale: PropTypes.string,
};

SEO.defaultProps = {
  title: null,
  desc: null,
  banner: null,
  pathname: null,
  article: false,
  node: null,
  locale: 'en-CA',
};

const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        siteUrl
        defaultBanner: banner
        ogLanguage
        author
        twitter
        facebook
      }
    }
  }
`;
