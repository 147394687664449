import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import {
  SiteLinkWrapper,
  SiteLikeLink,
  SiteLink,
  LinkBody,
  SiteLinkLine,
} from './styles';

const ThemeLink = ({
  style,
  to,
  target = '_self',
  childEl,
  children,
  onClick,
  theme = 'primary',
  className,
  linkClassName,
  bordered = true,
  topSpacingMobile,
  bottomSpacingMobile,
  topSpacingTablet,
  bottomSpacingTablet,
  topSpacingDesktop,
  bottomSpacingDesktop,
  icon,
  ariaLabel,
  ariaExpanded,
  exitDelay,
}) => {
  const themeLinkStyledProps = {
    style,
    bordered,
    topSpacingMobile,
    bottomSpacingMobile,
    topSpacingTablet,
    bottomSpacingTablet,
    topSpacingDesktop,
    bottomSpacingDesktop,
  };

  return (
    <SiteLinkWrapper
      className={`Site-Link-Wrapper ${theme} ${className ? className : ''}`}
      {...themeLinkStyledProps}
    >
      {to ? (
        <SiteLink
          role="link"
          title={children}
          className={`Site-Link ${linkClassName ? linkClassName : ''}`}
          to={to}
          target={target}
          exit={{ delay: exitDelay }}
        >
          <LinkBody className="Site-Link-Body" id="site-link-body">
            {children}
          </LinkBody>
          {childEl}
          <Icon type={icon ? icon : 'right-arrow'} />
        </SiteLink>
      ) : (
        <SiteLikeLink
          role="button"
          title={children}
          aria-label={ariaLabel}
          aria-expanded={ariaExpanded}
          className={`Site-Link ${linkClassName ? linkClassName : ''}`}
          onClick={onClick}
          exit={{ delay: exitDelay }}
        >
          <LinkBody className="Site-Link-Body" id="site-link-body">
            {children}
          </LinkBody>
          {childEl}
          <Icon type={icon ? icon : 'right-arrow'} />
        </SiteLikeLink>
      )}
      <SiteLinkLine id="site-link-line" />
    </SiteLinkWrapper>
  );
};

ThemeLink.propTypes = {
  to: PropTypes.string,
  target: PropTypes.string,
  children: PropTypes.string,
  onClick: PropTypes.func,
  theme: PropTypes.string,
  bordered: PropTypes.bool,
  className: PropTypes.string,
  linkClassName: PropTypes.string,
  exitDelay: PropTypes.number,
};

export default ThemeLink;
