import React from 'react';
import PropTypes from 'prop-types';
import { ContainerWrapperStyled, ContainerStyled } from './styles';

const Container = ({
  style,
  children = null,
  containerBackgroundColor,
  cap = '1140',
  topSpacingMobile,
  bottomSpacingMobile,
  topSpacingTablet,
  bottomSpacingTablet,
  topSpacingDesktop,
  bottomSpacingDesktop,
  spacedMobile = false,
  spacedTablet = false,
  spacedDesktop = false,
  widthMobile,
  widthTablet,
}) => {
  const containerStyledProps = {
    style,
    cap,
    containerBackgroundColor,
    topSpacingMobile,
    bottomSpacingMobile,
    topSpacingTablet,
    bottomSpacingTablet,
    topSpacingDesktop,
    bottomSpacingDesktop,
    widthMobile,
    widthTablet,
  };

  return (
    <ContainerWrapperStyled className="Wrapper" {...containerStyledProps}>
      <ContainerStyled
        className="Container"
        {...containerStyledProps}
        spacedMobile={spacedMobile}
        spacedTablet={spacedTablet}
        spacedDesktop={spacedDesktop}
      >
        {children}
      </ContainerStyled>
    </ContainerWrapperStyled>
  );
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  containerBackgroundColor: PropTypes.string,
  cap: PropTypes.string,
};

export default Container;
