import styled from 'styled-components';

export const ProgressBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: ${(props) => props.theme.colors.grey};
  z-index: 5;

  .progress-done {
    background-color: ${(props) => props.theme.colors.blue};
    height: 100%;
    width: 100%;
    transform-origin: center left;
  }
`;